@use "../../../assets/reusable.scss";
@import "../../../assets/variables.scss";

.handout-wrap {
  display: flex;
}

.handout-button {
  @include reusable.bigButton(white, $accent2, $accent2, white);
  animation: backAndforth 1s linear infinite;
  position: relative;
  transition: 0.3s;
  padding: 0.75rem 1.25rem;
  border-radius: 0;
  font-size: 1.25rem;
  background: $accent2;
  display: flex;
  align-items: center;
  gap: 10px;
  &:hover {
    animation: none;
    box-shadow: 8px 8px $accent2;
    > svg {
      * {
        stroke: $accent2;
      }
    }
  }
  p {
    margin: 0;
  }
  > svg {
    height: 40px;
    @media screen and (max-width: 481px) {
      height: 25px;
    }
    * {
      stroke: white;
    }
  }
  margin-bottom: 0;
}

@include reusable.backAndforth;
