$accent: #602c83;

$accent2: #2aa28f;
$color1: rgb(56, 110, 188);
$color2: #388abc;
$dark-blue: #333b70;

$grey1: #acacac;
$grey2: #575656;

$red: #ff4d4d;

$color3: white;
$color4: white;
$header-height: 4.5rem;
$sidebar-width: 20em;
$subheader-height: 3.5em;
$border: solid 1px black;
$min-width-content: 30em;
$box-shadow: 5px 5px 5px $grey1;
$course-material-width: calc(100% - $sidebar-width);
$course-material-min-width: none;

$font: 'Source Sans Pro', sans-serif;
$font2: 'Zen Antique', serif;
