@import '../../../assets/variables.scss';

.modal-wrap {
  display: flex;
  position: fixed;
  z-index: -10;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
  width: calc(90% - $sidebar-width);
  top: 2em;
  opacity: 0;
  transition: opacity 0.3s, z-index 0.3s;
  box-sizing: border-box;
  h2 {
    font-size: 1.25rem;
  }
  * {
    box-sizing: border-box;
  }
  @media screen and (max-width: 970px) {
    left: 0;
    right: 0;
    width: 100%;
    .grey-cover {
      cursor: auto;
      width: 100%;
      padding: 1rem;
      position: static;
    }
    .content-wrap {
      position: absolute;
      margin: 1rem;
    }
  }
  li {
    text-align: left;
  }
}

.grey-cover {
  background-color: grey;
  opacity: 0.5;
  height: 100%;
  width: calc(100% - $sidebar-width);
  position: fixed;
  z-index: 2000;
  left: $sidebar-width;
  top: $header-height;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.grey-cover:hover {
  cursor: pointer;
}

.content-wrap {
  transition: 0.25s;
  background-color: white;
  min-width: 30em;
  @media screen and (max-width: 970px) {
    min-width: 0;
  }
  max-width: 40em;
  z-index: 2001;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: black;
  border: solid 5px $accent2;
  box-shadow: 10px 10px 5px $grey2;
  position: relative;
  button.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
    padding: 0;
    border: 0;
    background: none;
    display: flex;
    :hover {
      fill: $red;
    }
    svg {
      width: 22px;
    }
  }
  @media screen and (max-width: 481px) {
    max-height: 67%;
    overflow: scroll;
  }
}

.show-me {
  z-index: 2;
  opacity: 1;
  transition: opacity 0.3s, z-index 0.3s;
}

.content-wrap {
  max-width: 90%;
  font-size: 1rem;
  font-weight: 500;
  p {
    text-align: start;
  }
}
