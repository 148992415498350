@import '../../../assets/variables.scss';

.logo {
  @media screen and (max-width: 500px) {
    width: 15em;
  }
  width: 20em;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  align-self: center;
  height: calc(5.5rem - 0.5em);
}

.login-wrap {
  .error {
    font-size: 1rem !important;
    text-align: start;
  }
  background-color: #fff;
  display: flex;
  flex-direction: column;
  border: 1px solid $dark-blue;

  .inner-wrap {
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    form {
      label {
        text-align: left;

        p {
          margin: 0.8em 0;
        }
      }
    }
  }
}

.auth-wrapper {
  box-sizing: border-box;
  min-height: 100vh;
  background-color: #eceef1;
  padding: 1rem;

  section {
    .logo {
      margin: 2rem auto;
    }

    .inner-wrap {
      padding: 2rem 3rem;
    }

    .login-wrap {
      border: #c9c9c9 solid 2px;
      border-radius: 2px;
      max-width: 400px;
      margin: auto;
    }
    h1 {
      margin: 0;
      font-size: 1.85rem;
      font-weight: 500;
      text-align: center;
      margin-bottom: 1rem;
    }
    form {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      label {
        p {
          font-size: 1rem;
          font-weight: 500;
        }
      }
      input {
        box-sizing: border-box;
        padding: 0.5rem;
        margin: 0;
        font-size: 1rem;
        width: 100%;
        border-radius: 5px;
        border: #c9c9c9 solid 2px;
        &:focus {
          outline: none !important;
          border: #378abd solid 2px;
          box-shadow: 0 0 10px #378abd;
        }
      }
      .button-wrap {
        margin: 1.5rem 0;
        margin-bottom: 1rem;
        button {
          width: 100%;
          font-size: 1rem;
          padding: 0.75rem 1.25rem;
          color: white;
          background: #378abd;
          border: black solid 1px;
          border-radius: 5px;
        }
      }
    }
  }
}

.login-wrap.login {
  min-height: 100vh;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  .inner-wrap {
    border: 1px solid black;
    padding: 2rem;
  }

  form {
    max-width: 200px;
    margin: auto;
    label {
      display: flex;
      flex-direction: column;

      input {
        padding: 0.25rem;
      }
    }
    .button-wrap {
      margin: 2rem;
      margin-bottom: 0;
      button {
        font-size: 1rem;
        padding: 0.25rem 0.5rem;
        font-weight: 500;
      }
    }
    p,
    button {
      font-size: 1.25rem;
    }
  }
}

.sign-in-b-wrapper {
  @media screen and (max-width: 500px) {
    justify-content: space-between;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  top: 1em;
  right: 1em;

  > div:not(.toggle) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    p {
      text-align: left;
      margin: 0;
      font-size: 1rem;
      font-size: 300;
      a {
        text-decoration: none;
        color: #5f2c86;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  a.sign-up,
  button:not(.toggle-section) {
    @media screen and (max-width: 500px) {
      padding: 0.5rem 0.75rem;
    }
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    color: white;
    background: #378abd;
    border: black solid 1px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 600;
  }
}

.right-section {
  display: flex;
  align-items: center;
  flex-direction: row !important;
  gap: 1rem !important;
}

.grey {
  margin-bottom: 0 !important;
  color: #378abd;
}

.phoneLabel {
  display: flex;
  align-items: start;
  gap: 1rem;
  margin-top: 0.8em;
  font-size: 1rem;
  color: rgb(70, 70, 70);
  text-decoration: underline;

  > input {
    width: 26px !important;
    height: 26px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    /* Create a custom radio button using pseudo-elements */
    position: relative;
    display: inline-block;
    border: 3px solid #378abd !important;
    border-radius: 10px !important;
    transition: border-color 0.3s ease-in-out;

    &:checked {
      border-color: #378abd;
    }
    &:checked::after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 13px;
      height: 13px;
      background-color: #378abd;
      border-radius: 50%;
    }
  }
}
> span {
}

.makeBlueText {
  color: #378abd;
  text-decoration: underline;
  text-decoration-color: #378abd !important;
}

.reset-p {
  margin: auto;
  min-height: auto !important;
  background-color: white;
}

.forgot-username {
  display: flex;
  align-items: center;
}

.success-wrap {
  text-align: left;
  h1 {
    color: green;
  }
  p {
    font-size: 1.15rem;
  }
}

.format {
  margin-top: 0.5rem;
}
