@import "../../assets/variables.scss";
.course-material {
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding-top: $header-height;
  width: $course-material-width;
  min-width: $course-material-min-width;
  height: 100%;
  margin-left: $sidebar-width;
  // height: calc(100% + $header-height + 1em);
  .sidebar-wrap {
    // display: none;
  }
}
