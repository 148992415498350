@import '../../../assets/variables.scss';

.bag-of-tricks-page {
  h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  width: 100%;
  box-sizing: border-box;
  .all-module-container {
    margin: 2rem;
    background: rgba(243, 243, 243, 255);
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (max-width: 970px) {
      margin-left: 0;
      margin-right: 0;
    }
    .card {
      background-color: white;
      padding: 2rem;
      border-radius: 10px;
      box-shadow: 10px 10px 12px 0px rgba(184, 184, 184, 1);
    }

    .module-title {
      text-align: left;
      font-size: 1.5rem;
      margin-top: 0;
    }

    .tricks-container {
      cursor: pointer;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;

      .trick-button-wrapper {
        border: 1px solid #000;
        padding: 1.25rem 1.5rem;
        font-weight: 600;
        font-size: 1rem;
        color: $color2;
        border: $color2 solid 3px;
        border-radius: 10px;
        box-shadow: 10px 10px 12px -4px rgba(212, 212, 212, 1);
        text-align: left;
        @media screen and (max-width: 970px) {
          font-size: 0.85rem;
        }

        &:hover {
          &:not(.active) {
            background: $color2;
            color: white;
          }
        }
        &.active {
          padding-right: 44px;
          position: relative;
          background-color: $color2;
          color: #fff;
          text-align: left;

          button {
            display: flex;
            padding: 5px;
            position: absolute;
            right: 0;
            top: 50%;
            /* bottom: 0; */
            right: 10px;
            transform: translateY(-50%);
            border: none;
            background: none;
            > svg {
              fill: white;

              &:hover {
                fill: red;
              }
            }
          }
        }
      }

      &.show-me {
        opacity: 0.7;
        transition: opacity 0.3s, z-index 0.3s;
      }

      &.hide-me {
        opacity: 0;
        display: none;
      }
    }
  }
}

.bot-wrap {
  width: 100%;
}
