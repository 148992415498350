@use "../../../assets/reusable.scss";
@import "../../../assets/variables.scss";

.devTip-display {
  @include reusable.bigButton(white, white, black, white);
  animation: backAndforth 1s linear infinite, oscillateBg 3s infinite;
  &:hover {
    animation: none;
    border: solid 2px $dark-blue;
    box-shadow: 8px 8px $dark-blue;
    color: $dark-blue;
    svg {
      animation: oscillateFill 5s infinite;
    }
  }
  svg {
    animation: none;
    color: white;
    fill: white;
  }
  .word-wrapper {
    display: flex;
    flex-direction: column;
  }
  svg {
    height: 40px;
    margin: 0 0.5rem;
    @media screen and (max-width: 481px) {
      height: 25px;
    }
  }
  margin-bottom: 0;
}

.devtip-content {
  h3 {
    margin-top: 0;
  }
  @media screen and (max-width: 481px) {
    font-size: 1rem;
  }
}

@include reusable.backAndforth;
@include reusable.oscillate(oscillateFill, fill);
@include reusable.oscillate(oscillateBg, background-color);
