@import '../../../assets/variables.scss';

.nav-button-wrap {
  position: fixed;
  width: calc(100% - $sidebar-width);
  margin-left: $sidebar-width;
  display: flex;
  justify-content: right;
  background-color: white;
  bottom: 0;
  left: 0;
  border-top: $border;
  line-height: 3.5em;
  align-items: center;

  .button-box {
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 3.5em;
    line-height: 3em;
    align-items: center;
    background-color: $color2;
  }

  button {
    border: $border;
    background-color: $dark-blue;

    height: 80%;
    // padding: 0 1em 0 1em;
    color: white;
    border-radius: 10px;
    // box-shadow: 5px 5px 10px rgb(120, 87, 87) ;
    font-size: 1rem;
    font-weight: 700;

    p {
      margin: 0;
      font-size: 1.3em;
    }
  }

  button:hover {
    background-color: $accent;
  }
}

.disable {
  background: grey !important;
}
