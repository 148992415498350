@import "../../assets/variables.scss";
.activity-wrap {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  // padding: 0.5em;
  border-radius: 10px;
  border: $dark-blue solid 4px;
  box-shadow: 5px 5px 5px $grey1;
  margin: 2rem 0;
  font-weight: 600;
  .icon-section {
    padding: 2rem;
    margin-bottom: 2rem;
  }
}
