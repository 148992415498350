.fade-content {
  .play-descriptive-commenting-1 {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 1;
    }
    .video-parent {
      order: 2;
    }
    .reflections-questions-wrap {
      order: 3;
    }
  }

  .ab-2 {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 1;
    }
    .video-parent {
      order: 2;
    }
  }

  .reward-behavior-intro {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 1;
    }
    .video-parent {
      order: 2;
    }
  }
  .rb-1 {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 1;
    }
    .video-parent {
      order: 2;
    }
  }
  .hr-intro {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 1;
    }
    .tricks-button {
      order: 3;
      align-self: start;
    }
    .video-parent {
      order: 2;
    }
  }
  .ps-2 {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 2;
    }
    .video-parent {
      order: 1;
    }
  }
  .et-1 {
    display: flex;
    flex-direction: column;
    .voiceOver {
      order: 1;
    }
    .video-parent {
      order: 2;
    }
  }
}
