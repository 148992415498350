@import '../../assets/variables.scss';

.checklist-wrapper {
  box-sizing: border-box;
  padding: 2rem;
  font-size: 1.25rem;
  border-radius: 10px;
  border: $color2 solid 4px;
  box-shadow: $box-shadow;

  h2 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .flex-me {
    display: flex;
    align-items: center;
  }

  .submit-button {
    margin-top: 1rem;
    transition: 0.25s;
    display: block;
    margin-left: auto;
    padding: 0.5rem 0.75rem;
    font-size: 1.25rem;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    background-color: $color2;
    color: white;
    box-shadow: $box-shadow;
    border: $color2 solid 2px;
    &:hover {
      color: $color2;
      background: white;
      border: $color2 solid 2px;
    }

    &.disable {
      cursor: default;
      background: grey;
      border: grey solid 1px;
      &:hover {
        color: white;
        background: grey;
        border: grey solid 1px;
      }
    }
  }

  input[type='text'] {
    border: none;
    padding: 0.5rem 0.25rem;
    font-size: 1rem;
    margin-left: 1rem;
    border-bottom: 2px solid black;
    border-radius: 0;
  }

  .question {
    display: grid;
    grid-template-columns: 1fr repeat(2, 50px);
    grid-auto-rows: auto;
    grid-gap: 1rem;
    gap: 2rem;
    align-items: center;
    & > p {
      white-space: word-break;
    }
    &.question-checkbox {
      @media screen and (max-width: 481px) {
        display: block;
      }
      .check-item-practice {
        margin: 0;
        font-size: 1rem;
      }
    }

    input[type='radio'] {
      width: 3em;
      height: 3rem;
      accent-color: $color2;
      margin: 0 auto;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.25rem;
      justify-self: center;
    }
    .span-2 {
      grid-column: span 2;

      input,
      select {
        width: 100%;
        overflow: hidden !important;
        font-size: 0.85rem;
      }
    }
    // .underline {
    //   width: 25px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   padding-bottom: 0.25rem;
    //   justify-self: center;
    //   input {
    //     margin: 0;
    //   }
    // }
  }

  .checklist-section {
    .drop-down {
      grid-column: 2 / -1;
    }

    .able-to {
      font-weight: 800;
    }
    .description {
      width: 100%;
    }

    .left-checklist {
      margin-left: auto;
      display: flex;
      column-gap: 3rem;
    }
    span {
      text-align: center;
      font-weight: 800;
    }
  }
}

.border-bottom {
  border-bottom: 2px solid $color2;
  margin-bottom: 1rem;
}

.ready-to-complete {
  font-size: 1.25rem;
  font-weight: 500;
  border-top: 2px dashed $color2;
  border-bottom: 2px dashed $color2;
  padding: 1.5rem 0;
  .submission-response {
    margin: 0;
    text-align: center;
    padding: 1.5rem;
    background: rgba(38, 226, 38, 0.804);
    color: black;
  }
  p {
    font-weight: bold;
    margin-top: 0;
  }

  > div {
    display: flex;
    gap: 1rem;
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    padding: 0.35rem 0.75rem;
    border: white solid 2px;
    border-radius: 5px;
    background: none;
    transition: all 0.25s;
    &:last-child {
      border-color: $red;
      color: white;
      background: $red;
      &:hover {
        background: white;
        color: $red;
      }
    }
    &:first-child {
      border-color: $accent2;
      color: white;
      background: $accent2;
      &:hover {
        background: white;
        color: $accent2;
      }
    }
  }
}

p.keep-practicing {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0;
}

.check-item-practice {
  margin: 1rem 0;
}
