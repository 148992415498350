@import "../../assets/variables.scss";
.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 2px;
  background: black;
  color: black;
  align-items: center;
  justify-content: center;
  padding: 2px 0;
  margin: 1rem;
  margin-top: 0;

  .question {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background: white;
    border-left: solid black 2px;
    height: 100%;
    @media screen and (max-width: 481px) {
      p {
        margin: 0.5rem 0;
      }
    }
  }

  .answer {
    p {
      padding: 1em;
      margin: 0;
      color: white;
    }
  }

  .question-dropable {
    border-right: solid black 2px;
    div.answer {
      padding: 0;
      height: 100%;
    }
  }
}

.answer-dropable {
  grid-column: 1 / -1;
  margin: 1rem;
  border: 2px solid black;
}
.answer-wrap {
  display: grid;
  grid-column: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 2rem;
  @media screen and (max-width: 481px) {
    padding: 1rem;
  }
  .answer {
    p {
      padding: 1em;
      margin: 0;
      color: white;
      @media screen and (max-width: 481px) {
        font-size: 0.85rem;
      }
    }
  }
}

.completed-response {
  font-size: 1.25rem;
  font-weight: 500;
  border-top: 2px dashed $color2;
  border-bottom: 2px dashed $color2;
  padding: 1.5rem 0;
  margin: 1rem;
  p {
    margin: 0;
    text-align: center;
    font-weight: bold;
    margin-top: 0;
    padding: 1.5rem;
    background: rgba(38, 226, 38, 0.804);
    color: black;
  }
}

.table-header {
  margin: 0 1rem;
  display: flex;
  margin-top: 1rem;
  font-size: 1.25rem;
  font-weight: bold;
  border-top: black solid 2px;
  border-left: black solid 2px;
  border-right: black solid 2px;
  background: #bbbbbb;
  span {
    text-align: center;
    flex: 1;
    padding: 0.5rem 0;
    &:first-child {
      border-right: black solid 2px;
    }
  }
}

.instruction-wrapper {
  margin: 1rem;
  padding: 1rem;
  border-bottom: black solid 2px;
  font-weight: 400;
  &.outro-wrapper {
    border-bottom: none;
  }
  p {
    &:first-child:not(.outro-item) {
      margin-top: 0;
      &:not(.outro-item) {
        font-weight: 400;
      }
    }
    &:last-child {
      &:not(.outro-item) {
        font-weight: bolder;
      }
      margin-bottom: 0;
    }
  }
}
