@use "../../../assets/reusable.scss";
@import "../../../assets/variables.scss";

.trick-wrapper {
  display: flex;
  flex-direction: column;

  .trick-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    button {
      font-size: 1.25rem;
      font-weight: 400;
      padding: 0.35rem 0.75rem;
      border: white solid 2px;
      border-radius: 5px;
      background: none;
      transition: all 0.25s;
      &:last-child {
        border-color: $red;
        color: $red;

        &:hover {
          background: $red;
          color: white;
        }
      }
      &:first-child {
        border-color: $accent2;
        color: $accent2;

        &:hover {
          background: $accent2;
          color: white;
        }
      }
      &:disabled {
        border: 1px solid #999999;
        color: lightgray;
        background-color: #bbbbbb;
        &:hover {
          background-color: #bbbbbb;
        }
      }
    }
  }

  p.trick-body {
    border-top: #28a28e solid 2px;
    border-bottom: #28a28e solid 2px;
    margin: 1.5rem 0;
    padding: 1rem 0;
    text-align: start;
    line-height: 20px;
  }

  .trick-in-bag-note {
    color: red;
    margin: 1em 0;
  }

  p.trick-question {
    font-weight: bold;
    font-size: 1rem;
    margin: 1.25rem;
    margin-top: 0;
  }

  .top-wrapper {
    height: auto;
    display: flex;
    margin: 0 auto;
    align-items: center;
    font-size: 1.25rem;
    color: $dark-blue;

    h4 {
      margin: 0;
      margin-left: 0.5rem;
    }
    svg {
      height: 30px;
      animation: tikTok 1s infinite ease-in-out;
      * {
        fill: $dark-blue;
      }
    }
  }
}

@keyframes tikTok {
  0%,
  100% {
    transform: rotate(-15deg);
  }
  50% {
    transform: rotate(15deg);
  }
}

.handout-wrap {
  display: flex;
}

.tricks-button {
  @include reusable.bigButton($dark-blue, $dark-blue, $dark-blue, white);
  gap: 10px;
  position: relative;
  transition: 0.3s;
  padding: 0.75rem 1.25rem;
  border-radius: 0;
  font-size: 1.25rem;
  background: white;
  animation: none;
  &:hover {
    animation: none;
    box-shadow: 8px 8px $dark-blue;
  }
  svg {
    height: 30px;
    animation: tikTok 1s infinite ease-in-out;
    * {
      fill: $dark-blue;
    }
    @media screen and (max-width: 481px) {
      height: 25px;
    }
  }
}

@include reusable.backAndforth;
