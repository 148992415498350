.icon-wrap {
  cursor: pointer;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  .icon-wrapper {
    width: 50px;
    img {
      width: 100%;
    }
  }
}
