@import '../../../assets/variables.scss';

// .handout-wrap {
//   align-items: left;
//   justify-content: left;
//   display: flex;
//   padding: 0.5em;
//   border-radius: 10px;
//   border: $border;
//   box-shadow: 5px 5px 5px $grey1;
//   margin-bottom: 1em;
// }

// .handout {
//   overflow: hidden;
//   width: 100%;
//   font-size: 1em;
// }

.badge {
  border-radius: 10px;
  padding: 1rem;
  background: white;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  h2 {
    margin-top: 0;
    white-space: nowrap;
  }
  transition: 0.5s;
  position: fixed;
  left: 100%;
  transition-timing-function: ease-in;
  &.open {
    left: 50%;
  }

  @media screen and (max-width: 481px) {
    h2 {
      font-size: 1rem !important;
    }
    img {
      height: 80px;
    }
  }
}

.badge-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
