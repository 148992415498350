@import '../../../assets/variables.scss';
.dragon-error-note {
  margin: 1em 0;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  background: red;
  padding: 1rem;
  transition: opacity 0.25s ease-in-out;
}
.dragon-act-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: 1em 0;

  .column {
    border-top: $border;
    border-left: $border;
    border-bottom: $border;
    border-right: $border;
    width: 33%;
    display: flex;
    flex-direction: column;
    &:last-child {
      border-right: $border;
    }
    h1 {
      padding: 0.3em 0.5em;
      margin: 0;
      @media screen and (max-width: 481px) {
        font-size: 1rem;
      }
    }
  }
  .draggable-area {
    padding: 8px;
    flex-grow: 1;
    min-height: 500px;
    transition: background-color 0.2s ease 0s;
  }
  h2 {
    text-align: center;
  }
  li {
    list-style-type: none;
  }
}

.column-2,
.column-3 {
  .draggable-area {
    .dragon-button {
      background: green;
    }
  }
}

.error-pop {
  border-radius: 10px;
  padding: 1rem;
  background: white;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background: red;
  border: black solid 3px;
  padding: 5px 10px;
  color: white;
  font-size: 1.25rem;
  padding: 1rem;
  /* border: white solid 2px; */
  font-weight: bold;
  position: fixed;
  top: 150px;
  p {
    margin: 0;
  }
  transition: 0.5s;
  position: fixed;
  right: -230px;
  transition-timing-function: ease-in;

  &.open {
    right: 40px;
  }

  @media screen and (max-width: 481px) {
    h2 {
      font-size: 1rem !important;
    }
  }
}
