@import './assets/variables.scss';
@import './assets/queries';
@import './assets/reusable.scss';

#App {
    font-family: $font;
    text-align: center;
    // height: calc(100vh - 3.5em);
}

button:hover {
    cursor: pointer;
}