.handout-wrapper {
  padding: 0 1rem;
  margin: auto;
}

.static-wrap {
  padding: 1.5rem 2rem;
  margin-top: 1rem;
  li {
    text-align: start;
  }
}

.handout-wrapper {
  .static-wrap {
    border: #388abc solid 4px;
    ul {
      display: flex;
      flex-direction: column;
      gap: 15px;
      font-size: 1.25rem;
      a {
        text-decoration: none;

        &:hover {
          color: #388abc;
          text-decoration: underline;
        }
      }
    }
  }
}
