@import '../../../assets/variables.scss';

.right-progress-wrap{
  position: fixed; 
  z-index: 3;
  top: $header-height;
  right:0;
  background-color: white;
  width: $sidebar-width;
  height: 100%;
  border-left:  $border
  h3{
    text-align: left;
    padding-left:1em;
    margin: 1.5em 0 0 0 ;
  }
  display: none;
}


.this-section-wrap{
  width: 90%;
  // height: 90%;
  display: flex; 
  flex-wrap: wrap;
  flex-direction: row;
  .progressbar-wrap{
    width: 15%;
    display: flex;
    flex-direction: column;
    // transform: rotate(90deg);
    height: 100%;
  }
}
.quick-links-wrap{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  text-align: left;
  a{  
    text-decoration: none;
  }
  a:visited{
    color: black;
  }
  li{ 
    padding: 1em 0 0 0;
    // list-style-type: none;
  }
  li:hover{
    cursor: pointer;
  }
  li::marker{
    color: white
    }
  
}
.section-links-wrap{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  a{
    text-decoration: none;
  }
  a:visited{
    color: black;
  }
  li{ 
    padding: 1em 0 0 0;
    
    // list-style-type: none;
  }
  li:hover{
    cursor: pointer;
  }
  li::marker{
    font-size: 1.5em;
    color: $color3
    }

  .rp-active-link{
    li::marker{
    color: $color2
    }
  }
}