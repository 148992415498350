@import '../../assets/variables.scss';

.plan-it-wrap {
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
  // padding: 0.5em;
  border-radius: 10px;
  border: $color2 solid 4px;
  box-shadow: 5px 5px 5px $grey1;
  margin: 2rem 0;
  font-weight: 600;
}

.routine {
  .question-wrapper {
    @media screen and (max-width: 481px) {
      ol {
        margin: 0;
      }
    }

    @media screen and (max-width: 970px) {
      ol {
        padding: 0 1rem;
      }
    }

    padding: 1rem;
    background: #bbbbbb61;
    > p {
      text-decoration: underline;
      margin-top: 0;
      font-weight: 600;
    }
    select {
      width: 100%;
      max-width: 400px;
      padding: 0.25rem;
      border: black solid 2px;
    }
  }
  .statement {
    & > p {
      margin: 0;
      text-decoration: none;
    }
  }
  .item-1 {
    color: black;
    padding: 0;
  }

  .outro {
    font-weight: 600;
    padding: 0 1rem;
    color: black;
  }
  .select-section {
    order: 2;
  }

  .item-2 {
    font-weight: bold;
    order: 3;
  }
  .padding-1 {
    padding: 0 1rem;
  }
}

.other-section {
}

.error {
  color: red;
  margin-left: 5px;
  font-weight: 600;
}

.intro-action {
  padding: 0 1rem;
  color: $dark-blue;
  font-weight: 600;
  font-size: 1.15rem;
}

ol.summary {
  padding-left: 1rem;
  margin: 0;
  li {
    margin: 0.75rem 0;
  }
  li:first-child {
    margin-top: 0;
  }
  li:last-child {
    margin-bottom: 0;
  }
}

.note {
  font-weight: 500;
}

.click-here {
  a {
    color: $color2;
  }
}
.add-items {
  margin: 1rem 0;
  input[type='text'] {
    border-radius: 0;
    border: none;
    padding: 0.5rem 0.25rem;
    font-size: 1rem;
    margin-left: 1rem;
    border-bottom: 2px solid black;
    width: 400px;
    @media screen and (max-width: 970px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

//submit button
// .flex-wrap {
//   display: flex;

//   .select-item-wrap {
//     border: black solid 1px;
//     border-radius: 5px;
//     overflow: hidden;
//     display: flex;
//     * {
//       border: none;
//       font-size: 1rem;
//     }
//     input[type="text"],
//     p {
//       padding: 0.5rem;
//       width: 166px;
//       margin: 0;
//       background: white;
//       font-weight: 400;
//       font-family: Arial, Helvetica, sans-serif;
//     }

//     button {
//       height: 100%;
//       background: $color2;
//       color: white;
//       font-weight: 600;
//     }
//   }
// }
.select-section {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  .select-item-wrap {
    margin: 0.5em;
    display: flex;
    flex-direction: row;

    label {
      @media screen and (max-width: 481px) {
        font-size: 1rem;
      }
    }

    input[type='checkbox'] {
      margin-right: 10px;
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
    div {
      display: flex;
      align-items: center;
    }
  }
}

.selected-ignore-options {
  line-height: 2em;
  color: $dark-blue;
}

.fill-in-placeholder {
  width: 100%;
  max-width: 400px;
  padding: 0.25rem;
  border: black solid 2px;
}

.column {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  input {
    max-width: none;
    width: 100%;
    box-sizing: border-box;
  }
}

.submit-button {
  border: solid 2px black;
  background-color: #388abc;
  height: 80%;
  color: white;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 700;
  align-self: start;
  padding: 0.5rem;
  margin-top: 0.5rem;
  &:hover {
    background: #388ebc;
  }
}

.underline {
  color: $dark-blue;
  text-decoration: underline;
  text-decoration-color: $dark-blue;
  display: inline-block;
}

.summary {
  ul {
    margin: 0.5rem 0;
  }
}

.margin-1 {
  margin: 1rem 0;
}

.done {
  margin: 1rem 0 !important;
  text-decoration: none !important;
  color: #019966;
}

.fillIn-text {
  margin: 0 0.5rem;
}

.flex-down {
  * {
    margin: 0;
  }
  input {
    width: 50%;
    margin: 1rem 0;
    height: 2rem;
  }
}
