@import "../../../assets/variables.scss";
.click-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .clickable-reveal-block {
    min-width: 25%;
    height: 8em;
    line-height: 8em;
    text-align: center;
    background-color: $color2;
    color: white;
    border-radius: 10px;
    font-size: 1.3em;
    margin: 1em;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    p {
      width: 100%;
      margin: 0;
      /* border: red solid 2px; */
      overflow-wrap: break-word;
      padding: 1rem;
    }
  }
  .clickable-reveal-line {
    width: 100%;
    text-align: left;
    background-color: $color2;
    color: white;
    border-radius: 10px;
    font-size: 1.25rem;
    @media screen and (max-width: 481px) {
      font-size: 1rem;
    }
    margin: 0.5em;
    padding: 0.5em 1em;
  }
  .clicked {
    background-color: $accent;
  }
  .clickable-reveal-block,
  .clickable-reveal-line {
    &:hover {
      cursor: pointer;
    }
  }
}

.content-wrap {
  section {
    border: black solid 2px;
    padding: 1rem;
    background: rgba(128, 128, 128, 0.151);
    h4 {
      font-size: 1.15rem;
      text-align: start;
    }
  }
}
