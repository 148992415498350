@import "../../../assets/variables.scss";

.reflections-questions-wrap {
  width: 100%;
}

.reflect-wrap {
  background-color: $color3;
  margin-bottom: 1em;
  padding: 0.5em;

  @media screen and (max-width: 481px) {
    & *:not(label) {
      font-size: 1rem !important;
    }
  }

  @media screen and (max-width: 481px) {
    padding: 0.75rem;
  }

  .reflect-header-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    margin-right: 1em;
    height: 3.5em;
    width: 3em;
  }

  h2 {
    color: $accent;
  }

  text-align: left;
  border-radius: 10px;
  border: $border;
  box-shadow: 5px 5px 5px $grey1;

  .rQuestion {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    @media screen and (max-width: 481px) {
      margin-bottom: 0.5rem;
    }
  }

  .rq-intro {
    padding-left: 1em;
    font-size: 1.25rem;
    font-weight: 550;
    margin: 1rem 0;
  }

  @media screen and (max-width: 481px) {
    ol {
      margin-top: 0;
      font-size: 1rem;
      li.rQuestion {
        font-size: 1rem;
      }
    }
    h2 {
      font-size: 1.25rem;
    }
  }
}

.answers-reflect-wrap {
  display: flex;
  flex-direction: column;
  // justify-content: left;
  min-height: 3em;
  font-size: 1.2em;

  .answers {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 10px;
    margin-bottom: 20px;
    .radio-options {
      display: flex;
      font-weight: bold;
      justify-content: left;
      align-items: baseline;
      gap: 5px;
    }
  }
}

.reflect-response {
  width: 100%;
  font-size: 1em;
}

.below {
  .radio-options {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;
    font-weight: 600;
    color: $accent;
    label {
      @media screen and (max-width: 481px) {
        font-size: 0.85rem;
      }
    }
    .letter {
      color: black;
    }
    input {
      margin: 0 0.5rem;
      accent-color: $accent;
    }
  }
}
