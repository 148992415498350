@use '../../../assets/reusable.scss';
@import '../../../assets/variables.scss';

.welcome {
  position: relative;
  margin: auto;
  width: 100%;
  margin: 0 2rem;
  .top-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: -webkit-auto;
  }
  .continue-button {
    @include reusable.bigButton(white, $accent2, $accent2, white);
    background-color: $accent2;
    font-size: 1.5rem;
    margin-bottom: 0;

    &:first-child {
      margin-top: 0;
    }
    &:hover {
      animation: none;
      box-shadow: 8px 8px $accent2;
    }
  }
  .module-button {
    @include reusable.bigButton(white, $color2, $color2, white);
    animation: backAndforth 1s linear infinite;
    background-color: $color2;
    font-size: 1.5rem;
    &:hover {
      animation: none;
      box-shadow: 8px 8px $color2;
    }
    animation: none;
    margin: 0.25rem;
    &.disable {
      cursor: default;
      background: grey;
      border: grey solid 1px;
      pointer-events: none;
    }
  }

  h1 {
    font-size: 4rem;
    margin: 0 auto;
    margin-top: 0;
    font-weight: 400;
    text-align: center;
  }
  .middle-wrapper {
    margin: 1rem 4rem;
    background: #80808014;
    padding: 2rem 1rem;
    border-radius: 10px;
    h3 {
      font-size: 1.5rem;
      text-align: start;
    }
    h2 {
      margin-top: 0;
      font-size: 2rem;
      color: $dark-blue;
    }
    .badge-flex {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 16px;
      margin-bottom: 2rem;
    }
    .continue-flex {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    section {
      box-sizing: border-box;
      flex: 1;
      padding: 1rem;
    }
  }
}

.greyed-out {
  filter: grayscale(1);
}

.sub-mod {
  margin-bottom: 3rem;
}

.supportButton {
  display: flex;
  gap: 10px;
  align-items: center;
  text-decoration: none;
  color: black;
  font-size: 1rem;
  color: red;
  font-weight: 500;
  border: red solid 2px;
  top: -36px;
  right: 20px;
  padding: 0.25rem 0.5rem;
  font-weight: 600;
  svg {
    height: 30px;
    > * {
      fill: white;
      stroke: $color2;
    }
  }

  p {
    margin: 0;
  }

  &:hover {
    color: white;
  }
}

.supportWrapper {
  display: flex;
  justify-content: end;
  margin: 2rem 0;
  a {
    width: 100%;
    justify-content: center;
    padding: 0.75rem;
  }
}

.front-button-wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 1rem 0 2rem 0;
}

@media screen and (max-width: 481px) {
  .welcome {
    h1 {
      font-size: 3rem;
    }
    .continue-button {
      font-size: 1rem;
    }
    .middle-wrapper {
      margin: 0;
    }
  }
}

@include reusable.backAndforth;

.disabled {
  > .continue-button {
    cursor: default;
    background: grey;
    border: grey solid 1px;
    box-shadow: none;
    pointer-events: none;
  }
}

.init-vid-p {
  font-size: 1.5rem;
}

.flex-center {
  display: flex;
  justify-content: center;

  .takeMeWhere {
    background-color: #333b70;
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    font-size: 1.25rem;
    font-weight: 800;
    margin: 1rem 0;
    display: flex;
    align-items: center;
    gap: 10px;
    &:hover {
      background: #602c83;
    }

    svg {
      height: 20px;
    }
  }
}

.badgey {
  width: 100px;
}

.missed {
  border-radius: 100px;
  position: relative;
  /* box-shadow: 0px 0px 0px #388abc; */
  animation: pulse 1.5s linear;
  animation-iteration-count: infinite;
  display: flex;

  .linkWrap {
    position: absolute;
    /* z-index: 100; */
    /* width: 70%; */
    top: 0;
    bottom: 0;
    z-index: 100;
    left: 0;
    border-radius: 1000px;
    right: 0;
    background: rgb(57 138 188 / 76%);
    &:hover {
      background: rgb(57 138 188 / 90%);
    }
  }

  svg {
    fill: white;
    position: absolute;
    /* z-index: 100; */
    width: 70%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}
// &::after {
//   animation: rainbow-bg 2.5s linear;
//   animation-iteration-count: infinite;
//   content: url('./exclamation-mark-svgrepo-com.svg');
//   fill: red;
//   display: inline-block;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   width: 50%;
// }

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px #5a99d46c;
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 0 #5a99d46c;
  }
}

@keyframes rainbow-bg {
  100%,
  0% {
    fill: rgb(255, 0, 0);
  }
  8% {
    fill: rgb(255, 127, 0);
  }
  16% {
    fill: rgb(255, 255, 0);
  }
  25% {
    fill: rgb(127, 255, 0);
  }
  33% {
    fill: rgb(0, 255, 0);
  }
  41% {
    fill: rgb(0, 255, 127);
  }
  50% {
    fill: rgb(0, 255, 255);
  }
  58% {
    fill: rgb(0, 127, 255);
  }
  66% {
    fill: rgb(0, 0, 255);
  }
  75% {
    fill: rgb(127, 0, 255);
  }
  83% {
    fill: rgb(255, 0, 255);
  }
  91% {
    fill: rgb(255, 0, 127);
  }
}

@keyframes border-color {
  100%,
  0% {
    border-color: rgb(255, 0, 0);
  }
  8% {
    border-color: rgb(255, 127, 0);
  }
  16% {
    border-color: rgb(255, 255, 0);
  }
  25% {
    border-color: rgb(127, 255, 0);
  }
  33% {
    border-color: rgb(0, 255, 0);
  }
  41% {
    border-color: rgb(0, 255, 127);
  }
  50% {
    border-color: rgb(0, 255, 255);
  }
  58% {
    border-color: rgb(0, 127, 255);
  }
  66% {
    border-color: rgb(0, 0, 255);
  }
  75% {
    border-color: rgb(127, 0, 255);
  }
  83% {
    border-color: rgb(255, 0, 255);
  }
  91% {
    border-color: rgb(255, 0, 127);
  }
}

.legend {
  display: flex;
  margin: 1rem 0;
  align-items: center;
  img {
    width: 40px;
  }
  span {
    text-align: left;
    font-size: 1rem;
    margin-left: 10px;
    font-weight: 700;
  }
}

.legendMissed {
  width: 40px;
  min-width: 40px;
  img {
    width: 100%;
  }
}

.legendWrap {
  background: white;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  gap: 0 1rem;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    justify-content: center;
  }
}
