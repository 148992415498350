@import "../../assets/variables.scss";
.flex-wrap {
  display: flex;

  .select-item-wrap {
    border: black solid 1px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    * {
      border: none;
      font-size: 1rem;
    }
    input[type="text"],
    p {
      padding: 0.5rem;
      width: 166px;
      margin: 0;
      background: white;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
    }

    button {
      height: 100%;
      background: $color2;
      color: white;
      font-weight: 600;
    }
  }
}
