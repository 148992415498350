@import '../../../assets/variables.scss';
.header-wrap {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 101;
  top: 0;
  width: 100vw;
  background-color: $color3;
  height: $header-height;

  border-bottom: $border;
  padding: 0 1rem;

  .jiggle {
    height: 40px;
    > svg {
      height: 40px;
    }
    animation: 0.5s 3 linear tikTok;
  }

  @keyframes tikTok {
    25% {
      transform: rotate(-15deg);
    }
    75% {
      transform: rotate(15deg);
    }
    100% {
      rotate: (0deg);
    }
  }

  .toggle-menu {
    z-index: 101;
    height: 1.5em;
    width: 2em;
    justify-content: space-between;
    background-color: $color2;
    // padding: .5em;
    border-radius: 0px 5px 10px 0px;
    visibility: hidden;
    display: none;

    .line {
      width: 100%;
      border-bottom: solid 0.5px black;
    }
  }

  .logo-wrap {
    @media screen and (max-width: 970px) {
      max-width: 150px;
    }

    display: flex;
    max-width: 250px;
    padding: 0.5rem;
    img {
      height: 100%;
    }
  }

  .item {
    min-width: 33%;
  }

  .icons-box {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 25px;
    > a {
      display: flex;
    }
    // height: $header-height;

    @media screen and (max-width: 970px) {
      display: none;
    }

    .icon {
      display: flex;
      height: 100%;
      width: 40px;
      // min-width: 20px;
      align-items: center;
      cursor: pointer;
    }

    svg {
      path {
        fill: $dark-blue;
      }

      circle {
        fill: $dark-blue;
      }

      rect {
        fill: $dark-blue;
      }
    }

    .active {
      svg {
        path {
          fill: $accent;
        }

        circle {
          fill: $accent;
        }
      }
    }
  }
}

.icon:hover {
  cursor: pointer;

  svg,
  svg * {
    path {
      fill: $accent;
    }

    circle {
      fill: $accent;
    }
    rect {
      fill: $accent;
    }
  }
}

.belowHeader {
  position: relative;
  width: 300px;
  left: 0;
  right: 0;
  border: #333b70 solid 2px;
  transform: translateX(-41%);
  top: 131%;
  background: white;
  z-index: 2;
  box-shadow: 5px 5px 5px grey;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.5rem;
  .triangle {
    z-index: 1;
    width: 21px;
    height: 21px;
    border: #333b70 solid 2px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    transform: rotate(45deg);
    top: -13px;
    border-radius: 5px 0 0 0;
    border-bottom: none;
    border-right: 0;
    background: white;
  }
  p {
    line-height: normal;
    margin: 0.25rem 0;
    font-size: 1rem;
    font-weight: bold;
  }
}

.closeNoti {
  background: none;
  border: none;
  padding: 0;
  box-sizing: border-box;
  height: 25px;
  width: 25px;
  padding: 5px;
  border-radius: 100px;
  display: flex;
  align-items: center;

  &:hover {
    * {
      fill: red !important;
    }
  }
}

.notifications {
  position: relative;
  box-sizing: border-box;
  .tip-wrapper {
    color: black;
  }
  .notifications-wrap {
    position: absolute;
    box-sizing: border-box;
    border: red solid 2px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: red;
    border-radius: 100px;
    right: -5px;
    top: 5px;
    font-weight: bold;
  }

  .belowHeader {
    position: absolute;
    max-width: 300px;
    left: 0;
    right: 0;
    border: #333b70 solid 2px;
    transform: translateX(-44%);
    top: 128;
    background: white;
    z-index: 2;
    box-shadow: 5px 5px 5px grey;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0;
    &.hide {
      display: none;
    }
    .header {
      margin: 0;
      padding: 0.75rem;
      background: #d5d5d5;
      color: black;
    }
    .tip-wrapper {
      font-weight: bold;
      padding: 0.5rem;
      margin: 0.5rem;
      border-top: #398abc solid 2px;
      border-bottom: #398abc solid 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover {
        background: #398abc;
        color: white;
        * {
          fill: white;
        }
      }
    }
    .triangle {
      z-index: -1;
      width: 21px;
      height: 21px;
      border: #333b70 solid 2px;
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg);
      top: -13px;
      border-radius: 5px 0 0 0;
      border-bottom: none;
      border-right: 0;
      background: #d5d5d5;
    }
  }

  .notification-badge {
  }
}

@media screen and (max-width: 970px) {
  .notifications {
    .triangle {
      display: none;
    }
    .tip-wrapper {
      &:hover {
        background: #398abc;
        color: white;
        * {
          fill: white !important;
        }
      }
    }
    .belowHeader {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      border-radius: 0px;
      position: fixed;
      top: 140px;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      transform: translateX(0px);
      bottom: auto;
      height: 100vh;
      width: 100vw;
      max-width: none;

      &.hideLittle {
        display: none;
      }
    }
  }
}

.toggle {
  display: flex;
  align-self: center;
  border-radius: 100px;
  box-shadow: 0 0 5px grey;
  font-weight: bold;
  overflow: hidden;
  background: rgb(237, 237, 237);
  flex-direction: row;
  color: black;
  > button.toggle-section {
    padding: 0.5rem 0.75rem;
    border: none;
    border-radius: 100px;
    background-color: inherit;
    font-weight: bold;
    &.active {
      background: #398abc;
      color: white;
    }
  }
}
